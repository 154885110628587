import { useTexture } from "@react-three/drei"
import { useLoader } from "@react-three/fiber"
import { useEffect, useMemo } from "react"
import * as THREE from 'three'
import { LinearEncoding, RepeatWrapping, TextureLoader } from "three"

export function Leather(){
    const name = (type) => `https://static.dbldose.com/vinarmourTextures/Leather_Brown_Shiny_${type}.webp`
    const [
        colorMap,
        normalMap,
        roughnessMap
      ] = useTexture([
        name("Color"),
        name("Normal"),
        name("Roughness")
      ])
    const leather = useMemo( () => {
        const leather =
            < meshStandardMaterial 
                map={ colorMap }
                normalMap={ normalMap }
                normalScale={(.01, .01)}
                roughness={ 2.0 }
                roughnessMap={ roughnessMap }
                metalness={ 0 }
                envMapIntensity={1}
            />
        return leather
    } , [] )
    return <>
            { leather }
        </>
}

export function Plastic(){
    const name = (type) => `https://static.dbldose.com/vinarmourTextures/TexturedPlastic_${type}.webp`
    const [
        colorMap,
        normalMap,
        roughnessMap
      ] = useTexture([
        name("Color"),
        name("Normal"),
        name("Roughness")
      ])
    const plastic = useMemo( () => {
        const plastic =
            < meshStandardMaterial 
                map={ colorMap }
                normalMap={ normalMap }
                normalScale={(.1, .1)}
                roughness={ 1.50 }
                roughnessMap={ roughnessMap }
                displacementMap={ roughnessMap }
                displacementScale={ 0.01 }
                displacementBias={ 0.02 }
                metalness={ 0 }
                envMapIntensity={1}
            />
        return plastic
    } , [] )
    return <>
            { plastic }
        </>
}

export function Twill(){
    const name = (type) => `https://static.dbldose.com/vinarmourTextures/Twill_${type}.webp`
    const [
        colorMap,
        heightMap,
        normalMap,
        roughnessMap,
        metalnessMap
      ] = useLoader( TextureLoader,[
        name("Color"),
        name("Height"),
        name("Normal"),
        name("Roughness"),
        name("Metallic")
      ])

    useEffect( () => {
        [
            colorMap,
            heightMap,
            normalMap,
            roughnessMap,
            metalnessMap
        ].forEach((t)=>{
            t.repeat.set(1.15,1.15)
            t.wrapS = t.wrapT = THREE.RepeatWrapping
        })

        normalMap.encoding = LinearEncoding

    } , [colorMap,
        heightMap,
        normalMap,
        roughnessMap,
        metalnessMap]
    )

    const twill = useMemo( () => {
        const twill =(
            < meshStandardMaterial 
                map={ colorMap }
                normalMap={ normalMap }
                normalScale={(3.0, 3.0)}
                roughness={ 1 }
                roughnessMap={ roughnessMap }
                displacementMap={ heightMap }
                displacementScale={ 0.01 }
                displacementBias={ 0.01 }
                metalnessMap={ metalnessMap }
                metalness={ 0.01 }
            />
        )    
        return twill
    } , [colorMap,
        heightMap,
        normalMap,
        roughnessMap,
        metalnessMap] )


    return <>
            { twill }
        </>
}

export function BrassPatinated(){
    const name = (type) => `https://static.dbldose.com/vinarmourTextures/Brass_Patinated_${type}.webp`
    const [
        colorMap,
        heightMap,
        normalMap,
        roughnessMap,
        metalnessMap
      ] = useLoader( TextureLoader,[
        name("Color"),
        name("Height"),
        name("Normal"),
        name("Roughness"),
        name("Metallic")
      ])

    useEffect( () => {
        [
            colorMap,
            heightMap,
            normalMap,
            roughnessMap,
            metalnessMap
        ].forEach((t)=>{
            t.repeat.set(1.15,1.15)
            t.wrapS = t.wrapT = THREE.RepeatWrapping
        })

        normalMap.encoding = LinearEncoding

    } , [colorMap,
        heightMap,
        normalMap,
        roughnessMap,
        metalnessMap]
    )

    const brassPatinated = useMemo( () => {
        const brassPatinated =(
            < meshStandardMaterial 
                map={ colorMap }
                normalMap={ normalMap }
                normalScale={(.85, .85)}
                roughness={ .637 }
                roughnessMap={ roughnessMap }
                metalnessMap={ metalnessMap }
                metalness={ 1.0 }
                envMapIntensity={3}
            />
        )    
        return brassPatinated
    } , [colorMap,
        heightMap,
        normalMap,
        roughnessMap,
        metalnessMap] )


    return <>
            { brassPatinated }
        </>
}

export function Brass(){
    const name = (type) => `https://static.dbldose.com/vinarmourTextures/Brass_${type}.webp`
    const [
        colorMap,
        normalMap,
        roughnessMap
      ] = useLoader( TextureLoader,[
        name("Color"),
        name("Normal"),
        name("Roughness")
      ])

    useEffect( () => {
        [
            colorMap,
            normalMap,
            roughnessMap
        ].forEach((t)=>{
            t.repeat.set(1.15,1.15)
            t.wrapS = t.wrapT = THREE.RepeatWrapping
        })

        normalMap.encoding = LinearEncoding

    } , [colorMap,
        normalMap,
        roughnessMap]
    )

    const brass = useMemo( () => {
        const brass =(
            < meshStandardMaterial 
                map={ colorMap }
                normalMap={ normalMap }
                normalScale={(.85, .85)}
                roughness={ .637 }
                roughnessMap={ roughnessMap }
                metalness={ 1.0 }
                envMapIntensity={1}
            />
        )    
        return brass
    } , [colorMap,
        normalMap,
        roughnessMap] )


    return <>
            { brass }
        </>
}

export function Thread(){
    const thread = useMemo( () => {
        const thread =(
            < meshBasicMaterial 
                color={0x1c1c1c}
            />
        )    
        return thread
    } , [] )


    return <>
            { thread }
        </>
}