import './style.css'
import ReactDOM from 'react-dom/client'
import { Canvas } from '@react-three/fiber'
import Flux from './Flux'
import Vinarmour from './Vinarmour'


const firstModel = ReactDOM.createRoot(document.querySelector('.webgl'))
const secondModel = ReactDOM.createRoot(document.querySelector('.scene1'))

firstModel.render(

  <Canvas
      shadows
      camera={ {
          fov: 55,
          near: 0.1,
          far: 200,
          position: [ - 3, 3, 7 ]
      } }
  >
    <Flux />
  </Canvas>
)

secondModel.render(
  <Canvas
      shadows
      camera={ {
          fov: 25,
          near: 0.1,
          far: 200,
          position: [ - 4, 3, 12 ]
      } }
  >
    <Vinarmour />
  </Canvas>
)
