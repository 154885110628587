import { SoftShadows, Environment, PresentationControls, ContactShadows } from "@react-three/drei";
import { Suspense } from 'react'
import VinarmourLG from "./VinarmourLG"

<SoftShadows
    frustum={3.75}
    size={0.005}
    near={9.5}
    samples={17}
    rings={11}
/>

export default function Vinarmour()
{


    return <>

        <Environment preset="warehouse" blur={ .5 } />

        <directionalLight
            position={ [ 1, 2, 3 ] }
            intensity={ 1.5 }
            castShadow
            shadow-mapSize={ [ 1024, 1024 ] }
            shadow-camera-near={ 1 }
            shadow-camera-far={ 10 }
            shadow-camera-top={ 5 }
            shadow-camera-right={ 5 }
            shadow-camera-bottom={ -5 }
            shadow-camera-left={ -5 }
            shadow-normalBias={ 0.04 }
        />
        <ambientLight intensity={ 1 } />
        <Suspense>
            <PresentationControls 
                global
                rotation={ [ 0.13, 0.1, 0 ] }
                polar={ [ - 0.75, 0.75 ] }
                azimuth={ [ - 1.75, 1.5 ] }
                // config={ { mass: 2, tension: 400 } }
                // snap={ { mass: 4, tension: 400 } }
            >
                <VinarmourLG scale={ .12 } position={ [-1.75, -1, 0] } rotation={ [2.5, 0.03, -2.0] } />
            </PresentationControls>
            <ContactShadows
                position-y={ -2 }
                opacity={ 0.4 }
                scale={ 10 }
                blur={ 2.4 }    
            />
        </Suspense>
    </>
}
