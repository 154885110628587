/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React from "react";
import { useGLTF } from "@react-three/drei";
import { Brass, BrassPatinated, Leather, Plastic, Thread, Twill } from "./VinarmourMaterials";


export default function VinarmourLG(props) {
  const { nodes, materials } = useGLTF("https://static.dbldose.com/vinarmourLG.glb");
  return (
    <group {...props} dispose={null}>
      <group>
        <group
          name="Threads"
          position={[6.03, 35.65, -0.76]}
          rotation={[1.58, 0.46, -1.6]}
        >
          <mesh
            name="Torus_23_remesh"
            geometry={nodes.Torus_23_remesh.geometry}
            position={[-0.1, -10.77, -5.42]}
            rotation={[0.42, 0.01, 0.08]}
          ><Thread /></mesh>
          <mesh
            name="Torus_22_remesh"
            geometry={nodes.Torus_22_remesh.geometry}
            position={[-0.1, -10.86, -5.21]}
            rotation={[0.42, 0.01, 0.08]}
          ><Thread /></mesh>
          <mesh
            name="Torus_21_remesh"
            geometry={nodes.Torus_21_remesh.geometry}
            position={[-0.1, -10.96, -5]}
            rotation={[0.42, 0.01, 0.08]}
          ><Thread /></mesh>
          <mesh
            name="Torus_20_remesh"
            geometry={nodes.Torus_20_remesh.geometry}
            position={[-0.1, -11.05, -4.79]}
            rotation={[0.42, 0.01, 0.08]}
          ><Thread /></mesh>
          <mesh
            name="Torus_19_remesh"
            geometry={nodes.Torus_19_remesh.geometry}
            position={[-0.09, -11.14, -4.6]}
            rotation={[0.42, 0.01, 0.08]}
          ><Thread /></mesh>
          <mesh
            name="Torus_18_remesh"
            geometry={nodes.Torus_18_remesh.geometry}
            position={[-0.09, -11.23, -4.4]}
            rotation={[0.42, 0.01, 0.08]}
          ><Thread /></mesh>
          <mesh
            name="Torus_17_remesh"
            geometry={nodes.Torus_17_remesh.geometry}
            position={[-0.09, -11.34, -4.17]}
            rotation={[0.42, 0.01, 0.08]}
          ><Thread /></mesh>
          <mesh
            name="Torus_16_remesh"
            geometry={nodes.Torus_16_remesh.geometry}
            position={[0.02, -11.37, -4.06]}
            rotation={[-1.28, 1.49, 1.7]}
          ><Thread /></mesh>
          <mesh
            name="Torus_15_remesh"
            geometry={nodes.Torus_15_remesh.geometry}
            position={[0.24, -11.36, -4.05]}
            rotation={[-1.28, 1.49, 1.7]}
          ><Thread /></mesh>
          <mesh
            name="Torus_14_remesh"
            geometry={nodes.Torus_14_remesh.geometry}
            position={[0.46, -11.34, -4.04]}
            rotation={[-1.28, 1.49, 1.7]}
          ><Thread /></mesh>
          <mesh
            name="Torus_13_remesh"
            geometry={nodes.Torus_13_remesh.geometry}
            position={[0.67, -11.32, -4.04]}
            rotation={[-1.28, 1.49, 1.7]}
          ><Thread /></mesh>
          <mesh
            name="Torus_12_remesh"
            geometry={nodes.Torus_12_remesh.geometry}
            position={[0.91, -11.3, -4.03]}
            rotation={[-1.28, 1.49, 1.7]}
          ><Thread /></mesh>
          <mesh
            name="Torus_11_remesh"
            geometry={nodes.Torus_11_remesh.geometry}
            position={[1.02, -11.25, -4.14]}
            rotation={[-2.72, -0.01, 3.06]}
          ><Thread /></mesh>
          <mesh
            name="Torus_10_remesh"
            geometry={nodes.Torus_10_remesh.geometry}
            position={[1.02, -11.15, -4.35]}
            rotation={[-2.72, -0.01, 3.06]}
          ><Thread /></mesh>
          <mesh
            name="Torus_9_remesh"
            geometry={nodes.Torus_9_remesh.geometry}
            position={[1.02, -11.06, -4.56]}
            rotation={[-2.72, -0.01, 3.06]}
          ><Thread /></mesh>
          <mesh
            name="Torus_8_remesh"
            geometry={nodes.Torus_8_remesh.geometry}
            position={[1.01, -10.96, -4.77]}
            rotation={[-2.72, -0.01, 3.06]}
          ><Thread /></mesh>
          <mesh
            name="Torus_7_remesh"
            geometry={nodes.Torus_7_remesh.geometry}
            position={[1.01, -10.87, -4.97]}
            rotation={[-2.72, -0.01, 3.06]}
          ><Thread /></mesh>
          <mesh
            name="Torus_6_remesh"
            geometry={nodes.Torus_6_remesh.geometry}
            position={[1.01, -10.78, -5.17]}
            rotation={[-2.72, -0.01, 3.06]}
          ><Thread /></mesh>
          <mesh
            name="Torus_5_remesh"
            geometry={nodes.Torus_5_remesh.geometry}
            position={[1.01, -10.69, -5.39]}
            rotation={[-2.72, -0.01, 3.06]}
          ><Thread /></mesh>
          <mesh
            name="Torus_4_remesh"
            geometry={nodes.Torus_4_remesh.geometry}
            position={[0.97, -10.61, -5.56]}
            rotation={[1.86, -1.49, 1.44]}
          ><Thread /></mesh>
          <mesh
            name="Torus_3_remesh"
            geometry={nodes.Torus_3_remesh.geometry}
            position={[0.75, -10.63, -5.56]}
            rotation={[1.86, -1.49, 1.44]}
          ><Thread /></mesh>
          <mesh
            name="Torus_2_remesh"
            geometry={nodes.Torus_2_remesh.geometry}
            position={[0.5, -10.65, -5.57]}
            rotation={[1.86, -1.49, 1.44]}
          ><Thread /></mesh>
          <mesh
            name="Torus_1_remesh"
            geometry={nodes.Torus_1_remesh.geometry}
            position={[0.27, -10.67, -5.57]}
            rotation={[1.86, -1.49, 1.44]}
          ><Thread /></mesh>
          <mesh
            name="Torus_0_remesh"
            geometry={nodes.Torus_0_remesh.geometry}
            position={[0.07, -10.68, -5.58]}
            rotation={[1.86, -1.49, 1.44]}
          ><Thread /></mesh>
          <mesh
            name="Torus_23_remesh_1"
            geometry={nodes.Torus_23_remesh_1.geometry}
            position={[-0.09, -10.74, -5.48]}
            rotation={[0.42, 0.01, 0.08]}
          ><Thread /></mesh>
          <mesh
            name="Torus_22_remesh_1"
            geometry={nodes.Torus_22_remesh_1.geometry}
            position={[-0.09, -10.84, -5.27]}
            rotation={[0.42, 0.01, 0.08]}
          ><Thread /></mesh>
          <mesh
            name="Torus_21_remesh_1"
            geometry={nodes.Torus_21_remesh_1.geometry}
            position={[-0.08, -10.93, -5.06]}
            rotation={[0.42, 0.01, 0.08]}
          ><Thread /></mesh>
          <mesh
            name="Torus_20_remesh_1"
            geometry={nodes.Torus_20_remesh_1.geometry}
            position={[-0.08, -11.02, -4.85]}
            rotation={[0.42, 0.01, 0.08]}
          ><Thread /></mesh>
          <mesh
            name="Torus_19_remesh_1"
            geometry={nodes.Torus_19_remesh_1.geometry}
            position={[-0.08, -11.12, -4.65]}
            rotation={[0.42, 0.01, 0.08]}
          ><Thread /></mesh>
          <mesh
            name="Torus_18_remesh_1"
            geometry={nodes.Torus_18_remesh_1.geometry}
            position={[-0.08, -11.21, -4.45]}
            rotation={[0.42, 0.01, 0.08]}
          ><Thread /></mesh>
          <mesh
            name="Torus_17_remesh_1"
            geometry={nodes.Torus_17_remesh_1.geometry}
            position={[-0.07, -11.31, -4.23]}
            rotation={[0.42, 0.01, 0.08]}
          ><Thread /></mesh>
          <mesh
            name="Torus_16_remesh_1"
            geometry={nodes.Torus_16_remesh_1.geometry}
            position={[0, -11.37, -4.08]}
            rotation={[-1.28, 1.49, 1.7]}
          ><Thread /></mesh>
          <mesh
            name="Torus_15_remesh_1"
            geometry={nodes.Torus_15_remesh_1.geometry}
            position={[0.22, -11.35, -4.07]}
            rotation={[-1.28, 1.49, 1.7]}
          ><Thread /></mesh>
          <mesh
            name="Torus_14_remesh_1"
            geometry={nodes.Torus_14_remesh_1.geometry}
            position={[0.44, -11.33, -4.07]}
            rotation={[-1.28, 1.49, 1.7]}
          ><Thread /></mesh>
          <mesh
            name="Torus_13_remesh_1"
            geometry={nodes.Torus_13_remesh_1.geometry}
            position={[0.66, -11.31, -4.06]}
            rotation={[-1.28, 1.49, 1.7]}
          ><Thread /></mesh>
          <mesh
            name="Torus_12_remesh_1"
            geometry={nodes.Torus_12_remesh_1.geometry}
            position={[0.89, -11.3, -4.06]}
            rotation={[-1.28, 1.49, 1.7]}
          ><Thread /></mesh>
          <mesh
            name="Torus_11_remesh_1"
            geometry={nodes.Torus_11_remesh_1.geometry}
            position={[1, -11.24, -4.16]}
            rotation={[-2.72, -0.01, 3.06]}
          ><Thread /></mesh>
          <mesh
            name="Torus_10_remesh_1"
            geometry={nodes.Torus_10_remesh_1.geometry}
            position={[1, -11.14, -4.37]}
            rotation={[-2.72, -0.01, 3.06]}
          ><Thread /></mesh>
          <mesh
            name="Torus_9_remesh_1"
            geometry={nodes.Torus_9_remesh_1.geometry}
            position={[1, -11.05, -4.58]}
            rotation={[-2.72, -0.01, 3.06]}
          ><Thread /></mesh>
          <mesh
            name="Torus_8_remesh_1"
            geometry={nodes.Torus_8_remesh_1.geometry}
            position={[1, -10.96, -4.79]}
            rotation={[-2.72, -0.01, 3.06]}
          ><Thread /></mesh>
          <mesh
            name="Torus_7_remesh_1"
            geometry={nodes.Torus_7_remesh_1.geometry}
            position={[1, -10.86, -4.99]}
            rotation={[-2.72, -0.01, 3.06]}
          ><Thread /></mesh>
          <mesh
            name="Torus_6_remesh_1"
            geometry={nodes.Torus_6_remesh_1.geometry}
            position={[0.99, -10.77, -5.19]}
            rotation={[-2.72, -0.01, 3.06]}
          ><Thread /></mesh>
          <mesh
            name="Torus_5_remesh_1"
            geometry={nodes.Torus_5_remesh_1.geometry}
            position={[0.99, -10.68, -5.41]}
            rotation={[-2.72, -0.01, 3.06]}
          ><Thread /></mesh>
          <mesh
            name="Torus_4_remesh_1"
            geometry={nodes.Torus_4_remesh_1.geometry}
            position={[0.91, -10.63, -5.53]}
            rotation={[1.86, -1.49, 1.44]}
          ><Thread /></mesh>
          <mesh
            name="Torus_3_remesh_1"
            geometry={nodes.Torus_3_remesh_1.geometry}
            position={[0.68, -10.65, -5.54]}
            rotation={[1.86, -1.49, 1.44]}
          ><Thread /></mesh>
          <mesh
            name="Torus_2_remesh_1"
            geometry={nodes.Torus_2_remesh_1.geometry}
            position={[0.44, -10.66, -5.55]}
            rotation={[1.86, -1.49, 1.44]}
          ><Thread /></mesh>
          <mesh
            name="Torus_1_remesh_1"
            geometry={nodes.Torus_1_remesh_1.geometry}
            position={[0.22, -10.68, -5.55]}
            rotation={[1.86, -1.49, 1.44]}
          ><Thread /></mesh>
          <mesh
            name="Torus_0_remesh_1"
            geometry={nodes.Torus_0_remesh_1.geometry}
            position={[0, -10.7, -5.56]}
            rotation={[1.86, -1.49, 1.44]}
          ><Thread /></mesh>
          <mesh
            name="Torus_22_remesh_2"
            geometry={nodes.Torus_22_remesh_2.geometry}
            position={[-0.07, -12.65, -1.21]}
            rotation={[0.42, 0.01, 0.08]}
          ><Thread /></mesh>
          <mesh
            name="Torus_21_remesh_2"
            geometry={nodes.Torus_21_remesh_2.geometry}
            position={[-0.07, -12.74, -1]}
            rotation={[0.42, 0.01, 0.08]}
          ><Thread /></mesh>
          <mesh
            name="Torus_20_remesh_2"
            geometry={nodes.Torus_20_remesh_2.geometry}
            position={[-0.07, -12.83, -0.79]}
            rotation={[0.42, 0.01, 0.08]}
          ><Thread /></mesh>
          <mesh
            name="Torus_19_remesh_2"
            geometry={nodes.Torus_19_remesh_2.geometry}
            position={[-0.06, -12.92, -0.6]}
            rotation={[0.42, 0.01, 0.08]}
          ><Thread /></mesh>
          <mesh
            name="Torus_18_remesh_2"
            geometry={nodes.Torus_18_remesh_2.geometry}
            position={[-0.06, -13.01, -0.39]}
            rotation={[0.42, 0.04, 0.08]}
          ><Thread /></mesh>
          <mesh
            name="Torus_17_remesh_2"
            geometry={nodes.Torus_17_remesh_2.geometry}
            position={[-0.01, -13.11, -0.17]}
            rotation={[0.38, 0.44, 0.09]}
          ><Thread /></mesh>
          <mesh
            name="Torus_16_remesh_2"
            geometry={nodes.Torus_16_remesh_2.geometry}
            position={[0.13, -13.17, 0]}
            rotation={[0.35, 0.69, 0.11]}
          ><Thread /></mesh>
          <mesh
            name="Torus_15_remesh_2"
            geometry={nodes.Torus_15_remesh_2.geometry}
            position={[0.27, -13.23, 0.16]}
            rotation={[0.35, 0.69, 0.11]}
          ><Thread /></mesh>
          <mesh
            name="Torus_14_remesh_2"
            geometry={nodes.Torus_14_remesh_2.geometry}
            position={[0.4, -13.29, 0.32]}
            rotation={[0.35, 0.69, 0.11]}
          ><Thread /></mesh>
          <mesh
            name="Torus_13_remesh_2"
            geometry={nodes.Torus_13_remesh_2.geometry}
            position={[0.57, -13.32, 0.43]}
            rotation={[-2.57, 1.06, 2.97]}
          ><Thread /></mesh>
          <mesh
            name="Torus_12_remesh_2"
            geometry={nodes.Torus_12_remesh_2.geometry}
            position={[0.71, -13.24, 0.27]}
            rotation={[-2.66, 0.64, 3.04]}
          ><Thread /></mesh>
          <mesh
            name="Torus_11_remesh_2"
            geometry={nodes.Torus_11_remesh_2.geometry}
            position={[0.85, -13.15, 0.1]}
            rotation={[-2.66, 0.64, 3.04]}
          ><Thread /></mesh>
          <mesh
            name="Torus_10_remesh_2"
            geometry={nodes.Torus_10_remesh_2.geometry}
            position={[0.99, -13.06, -0.07]}
            rotation={[-2.66, 0.62, 3.04]}
          ><Thread /></mesh>
          <mesh
            name="Torus_9_remesh_2"
            geometry={nodes.Torus_9_remesh_2.geometry}
            position={[1.08, -12.97, -0.25]}
            rotation={[-2.71, 0.13, 3.06]}
          ><Thread /></mesh>
          <mesh
            name="Torus_8_remesh_2"
            geometry={nodes.Torus_8_remesh_2.geometry}
            position={[1.09, -12.88, -0.46]}
            rotation={[-2.72, -0.01, 3.06]}
          ><Thread /></mesh>
          <mesh
            name="Torus_7_remesh_2"
            geometry={nodes.Torus_7_remesh_2.geometry}
            position={[1.08, -12.79, -0.67]}
            rotation={[-2.72, -0.01, 3.06]}
          ><Thread /></mesh>
          <mesh
            name="Torus_6_remesh_2"
            geometry={nodes.Torus_6_remesh_2.geometry}
            position={[1.08, -12.7, -0.86]}
            rotation={[-2.72, -0.01, 3.06]}
          ><Thread /></mesh>
          <mesh
            name="Torus_5_remesh_2"
            geometry={nodes.Torus_5_remesh_2.geometry}
            position={[1.08, -12.6, -1.08]}
            rotation={[-2.72, -0.01, 3.06]}
          ><Thread /></mesh>
          <mesh
            name="Torus_4_remesh_2"
            geometry={nodes.Torus_4_remesh_2.geometry}
            position={[1, -12.55, -1.21]}
            rotation={[1.86, -1.49, 1.44]}
          ><Thread /></mesh>
          <mesh
            name="Torus_3_remesh_2"
            geometry={nodes.Torus_3_remesh_2.geometry}
            position={[0.78, -12.57, -1.21]}
            rotation={[1.86, -1.49, 1.44]}
          ><Thread /></mesh>
          <mesh
            name="Torus_2_remesh_2"
            geometry={nodes.Torus_2_remesh_2.geometry}
            position={[0.52, -12.59, -1.22]}
            rotation={[1.86, -1.49, 1.44]}
          ><Thread /></mesh>
          <mesh
            name="Torus_1_remesh_2"
            geometry={nodes.Torus_1_remesh_2.geometry}
            position={[0.3, -12.61, -1.22]}
            rotation={[1.86, -1.49, 1.44]}
          ><Thread /></mesh>
          <mesh
            name="Torus_0_remesh_2"
            geometry={nodes.Torus_0_remesh_2.geometry}
            position={[0.09, -12.63, -1.23]}
            rotation={[1.86, -1.49, 1.44]}
          ><Thread /></mesh>
          <mesh
            name="Torus_22_remesh_3"
            geometry={nodes.Torus_22_remesh_3.geometry}
            position={[-0.04, -12.66, -1.17]}
            rotation={[0.42, 0.01, 0.08]}
          ><Thread /></mesh>
          <mesh
            name="Torus_21_remesh_3"
            geometry={nodes.Torus_21_remesh_3.geometry}
            position={[-0.03, -12.76, -0.96]}
            rotation={[0.42, 0.01, 0.08]}
          ><Thread /></mesh>
          <mesh
            name="Torus_20_remesh_3"
            geometry={nodes.Torus_20_remesh_3.geometry}
            position={[-0.03, -12.85, -0.75]}
            rotation={[0.42, 0.01, 0.08]}
          ><Thread /></mesh>
          <mesh
            name="Torus_19_remesh_3"
            geometry={nodes.Torus_19_remesh_3.geometry}
            position={[-0.03, -12.94, -0.55]}
            rotation={[0.42, 0.01, 0.08]}
          ><Thread /></mesh>
          <mesh
            name="Torus_18_remesh_3"
            geometry={nodes.Torus_18_remesh_3.geometry}
            position={[-0.02, -13.03, -0.34]}
            rotation={[0.42, 0.03, 0.08]}
          ><Thread /></mesh>
          <mesh
            name="Torus_17_remesh_3"
            geometry={nodes.Torus_17_remesh_3.geometry}
            position={[0.04, -13.12, -0.13]}
            rotation={[0.38, 0.49, 0.09]}
          ><Thread /></mesh>
          <mesh
            name="Torus_16_remesh_3"
            geometry={nodes.Torus_16_remesh_3.geometry}
            position={[0.17, -13.19, 0.04]}
            rotation={[0.36, 0.64, 0.1]}
          ><Thread /></mesh>
          <mesh
            name="Torus_15_remesh_3"
            geometry={nodes.Torus_15_remesh_3.geometry}
            position={[0.3, -13.25, 0.21]}
            rotation={[0.36, 0.64, 0.1]}
          ><Thread /></mesh>
          <mesh
            name="Torus_14_remesh_3"
            geometry={nodes.Torus_14_remesh_3.geometry}
            position={[0.43, -13.31, 0.38]}
            rotation={[0.36, 0.64, 0.1]}
          ><Thread /></mesh>
          <mesh
            name="Torus_13_remesh_3"
            geometry={nodes.Torus_13_remesh_3.geometry}
            position={[0.6, -13.31, 0.42]}
            rotation={[-2.67, 0.59, 3.04]}
          ><Thread /></mesh>
          <mesh
            name="Torus_12_remesh_3"
            geometry={nodes.Torus_12_remesh_3.geometry}
            position={[0.73, -13.23, 0.25]}
            rotation={[-2.67, 0.59, 3.04]}
          ><Thread /></mesh>
          <mesh
            name="Torus_11_remesh_3"
            geometry={nodes.Torus_11_remesh_3.geometry}
            position={[0.86, -13.14, 0.08]}
            rotation={[-2.67, 0.59, 3.04]}
          ><Thread /></mesh>
          <mesh
            name="Torus_10_remesh_3"
            geometry={nodes.Torus_10_remesh_3.geometry}
            position={[0.99, -13.05, -0.09]}
            rotation={[-2.68, 0.51, 3.05]}
          ><Thread /></mesh>
          <mesh
            name="Torus_9_remesh_3"
            geometry={nodes.Torus_9_remesh_3.geometry}
            position={[1.05, -12.96, -0.29]}
            rotation={[-2.72, 0.02, 3.06]}
          ><Thread /></mesh>
          <mesh
            name="Torus_8_remesh_3"
            geometry={nodes.Torus_8_remesh_3.geometry}
            position={[1.05, -12.86, -0.5]}
            rotation={[-2.72, -0.01, 3.06]}
          ><Thread /></mesh>
          <mesh
            name="Torus_7_remesh_3"
            geometry={nodes.Torus_7_remesh_3.geometry}
            position={[1.05, -12.77, -0.7]}
            rotation={[-2.72, -0.01, 3.06]}
          ><Thread /></mesh>
          <mesh
            name="Torus_6_remesh_3"
            geometry={nodes.Torus_6_remesh_3.geometry}
            position={[1.04, -12.68, -0.91]}
            rotation={[-2.72, -0.01, 3.06]}
          ><Thread /></mesh>
          <mesh
            name="Torus_5_remesh_3"
            geometry={nodes.Torus_5_remesh_3.geometry}
            position={[1.04, -12.59, -1.12]}
            rotation={[-2.72, -0.01, 3.06]}
          ><Thread /></mesh>
          <mesh
            name="Torus_4_remesh_3"
            geometry={nodes.Torus_4_remesh_3.geometry}
            position={[0.96, -12.54, -1.25]}
            rotation={[1.86, -1.49, 1.44]}
          ><Thread /></mesh>
          <mesh
            name="Torus_3_remesh_3"
            geometry={nodes.Torus_3_remesh_3.geometry}
            position={[0.73, -12.56, -1.25]}
            rotation={[1.86, -1.49, 1.44]}
          ><Thread /></mesh>
          <mesh
            name="Torus_2_remesh_3"
            geometry={nodes.Torus_2_remesh_3.geometry}
            position={[0.49, -12.58, -1.26]}
            rotation={[1.86, -1.49, 1.44]}
          ><Thread /></mesh>
          <mesh
            name="Torus_1_remesh_3"
            geometry={nodes.Torus_1_remesh_3.geometry}
            position={[0.27, -12.59, -1.26]}
            rotation={[1.86, -1.49, 1.44]}
          ><Thread /></mesh>
          <mesh
            name="Torus_0_remesh_3"
            geometry={nodes.Torus_0_remesh_3.geometry}
            position={[0.05, -12.61, -1.27]}
            rotation={[1.86, -1.49, 1.44]}
          ><Thread /></mesh>
          <mesh
            name="Torus_22_remesh_4"
            geometry={nodes.Torus_22_remesh_4.geometry}
            position={[0.57, -2.49, 3.55]}
            rotation={[0.48, 0, -Math.PI]}
          ><Thread /></mesh>
          <mesh
            name="Torus_21_remesh_4"
            geometry={nodes.Torus_21_remesh_4.geometry}
            position={[0.57, -2.6, 3.76]}
            rotation={[0.48, 0, -Math.PI]}
          ><Thread /></mesh>
          <mesh
            name="Torus_20_remesh_4"
            geometry={nodes.Torus_20_remesh_4.geometry}
            position={[0.57, -2.7, 3.96]}
            rotation={[0.48, 0, -Math.PI]}
          ><Thread /></mesh>
          <mesh
            name="Torus_19_remesh_4"
            geometry={nodes.Torus_19_remesh_4.geometry}
            position={[0.57, -2.8, 4.15]}
            rotation={[0.48, 0, -Math.PI]}
          ><Thread /></mesh>
          <mesh
            name="Torus_18_remesh_4"
            geometry={nodes.Torus_18_remesh_4.geometry}
            position={[0.57, -2.91, 4.35]}
            rotation={[0.48, -0.03, -Math.PI]}
          ><Thread /></mesh>
          <mesh
            name="Torus_17_remesh_4"
            geometry={nodes.Torus_17_remesh_4.geometry}
            position={[0.52, -3.02, 4.56]}
            rotation={[0.48, -0.44, -Math.PI]}
          ><Thread /></mesh>
          <mesh
            name="Torus_16_remesh_4"
            geometry={nodes.Torus_16_remesh_4.geometry}
            position={[0.39, -3.11, 4.73]}
            rotation={[0.48, -0.68, Math.PI]}
          ><Thread /></mesh>
          <mesh
            name="Torus_15_remesh_4"
            geometry={nodes.Torus_15_remesh_4.geometry}
            position={[0.25, -3.19, 4.88]}
            rotation={[0.48, -0.68, Math.PI]}
          ><Thread /></mesh>
          <mesh
            name="Torus_14_remesh_4"
            geometry={nodes.Torus_14_remesh_4.geometry}
            position={[0.11, -3.26, 5.03]}
            rotation={[0.48, -0.68, Math.PI]}
          ><Thread /></mesh>
          <mesh
            name="Torus_13_remesh_4"
            geometry={nodes.Torus_13_remesh_4.geometry}
            position={[-0.05, -3.32, 5.13]}
            rotation={[-2.66, -1.08, 0]}
          ><Thread /></mesh>
          <mesh
            name="Torus_12_remesh_4"
            geometry={nodes.Torus_12_remesh_4.geometry}
            position={[-0.19, -3.23, 4.97]}
            rotation={[-2.66, -0.66, 0]}
          ><Thread /></mesh>
          <mesh
            name="Torus_11_remesh_4"
            geometry={nodes.Torus_11_remesh_4.geometry}
            position={[-0.34, -3.15, 4.8]}
            rotation={[-2.66, -0.66, 0]}
          ><Thread /></mesh>
          <mesh
            name="Torus_10_remesh_4"
            geometry={nodes.Torus_10_remesh_4.geometry}
            position={[-0.48, -3.06, 4.64]}
            rotation={[-2.66, -0.63, 0]}
          ><Thread /></mesh>
          <mesh
            name="Torus_9_remesh_4"
            geometry={nodes.Torus_9_remesh_4.geometry}
            position={[-0.57, -2.97, 4.46]}
            rotation={[-2.66, -0.14, 0]}
          ><Thread /></mesh>
          <mesh
            name="Torus_8_remesh_4"
            geometry={nodes.Torus_8_remesh_4.geometry}
            position={[-0.58, -2.86, 4.25]}
            rotation={[-2.66, 0, 0]}
          ><Thread /></mesh>
          <mesh
            name="Torus_7_remesh_4"
            geometry={nodes.Torus_7_remesh_4.geometry}
            position={[-0.58, -2.76, 4.05]}
            rotation={[-2.66, 0, 0]}
          ><Thread /></mesh>
          <mesh
            name="Torus_6_remesh_4"
            geometry={nodes.Torus_6_remesh_4.geometry}
            position={[-0.58, -2.65, 3.86]}
            rotation={[-2.66, 0, 0]}
          ><Thread /></mesh>
          <mesh
            name="Torus_5_remesh_4"
            geometry={nodes.Torus_5_remesh_4.geometry}
            position={[-0.58, -2.55, 3.65]}
            rotation={[-2.66, 0, 0]}
          ><Thread /></mesh>
          <mesh
            name="Torus_4_remesh_4"
            geometry={nodes.Torus_4_remesh_4.geometry}
            position={[-0.5, -2.48, 3.53]}
            rotation={[-2.66, Math.PI / 2, 0]}
          ><Thread /></mesh>
          <mesh
            name="Torus_3_remesh_4"
            geometry={nodes.Torus_3_remesh_4.geometry}
            position={[-0.28, -2.48, 3.53]}
            rotation={[-2.66, Math.PI / 2, 0]}
          ><Thread /></mesh>
          <mesh
            name="Torus_2_remesh_4"
            geometry={nodes.Torus_2_remesh_4.geometry}
            position={[-0.03, -2.48, 3.53]}
            rotation={[-2.66, Math.PI / 2, 0]}
          ><Thread /></mesh>
          <mesh
            name="Torus_1_remesh_4"
            geometry={nodes.Torus_1_remesh_4.geometry}
            position={[0.2, -2.48, 3.53]}
            rotation={[-2.66, Math.PI / 2, 0]}
          ><Thread /></mesh>
          <mesh
            name="Torus_0_remesh_4"
            geometry={nodes.Torus_0_remesh_4.geometry}
            position={[0.41, -2.48, 3.53]}
            rotation={[-2.66, Math.PI / 2, 0]}
          ><Thread /></mesh>
          <mesh
            name="Torus_22_remesh_5"
            geometry={nodes.Torus_22_remesh_5.geometry}
            position={[0.54, -2.51, 3.59]}
            rotation={[0.48, 0, -Math.PI]}
          ><Thread /></mesh>
          <mesh
            name="Torus_21_remesh_5"
            geometry={nodes.Torus_21_remesh_5.geometry}
            position={[0.54, -2.62, 3.8]}
            rotation={[0.48, 0, -Math.PI]}
          ><Thread /></mesh>
          <mesh
            name="Torus_20_remesh_5"
            geometry={nodes.Torus_20_remesh_5.geometry}
            position={[0.54, -2.73, 4]}
            rotation={[0.48, 0, -Math.PI]}
          ><Thread /></mesh>
          <mesh
            name="Torus_19_remesh_5"
            geometry={nodes.Torus_19_remesh_5.geometry}
            position={[0.54, -2.83, 4.19]}
            rotation={[0.48, 0, -Math.PI]}
          ><Thread /></mesh>
          <mesh
            name="Torus_18_remesh_5"
            geometry={nodes.Torus_18_remesh_5.geometry}
            position={[0.54, -2.93, 4.39]}
            rotation={[0.48, -0.02, -Math.PI]}
          ><Thread /></mesh>
          <mesh
            name="Torus_17_remesh_5"
            geometry={nodes.Torus_17_remesh_5.geometry}
            position={[0.48, -3.04, 4.6]}
            rotation={[0.48, -0.49, Math.PI]}
          ><Thread /></mesh>
          <mesh
            name="Torus_16_remesh_5"
            geometry={nodes.Torus_16_remesh_5.geometry}
            position={[0.35, -3.13, 4.76]}
            rotation={[0.48, -0.63, -Math.PI]}
          ><Thread /></mesh>
          <mesh
            name="Torus_15_remesh_5"
            geometry={nodes.Torus_15_remesh_5.geometry}
            position={[0.22, -3.21, 4.92]}
            rotation={[0.48, -0.63, -Math.PI]}
          ><Thread /></mesh>
          <mesh
            name="Torus_14_remesh_5"
            geometry={nodes.Torus_14_remesh_5.geometry}
            position={[0.09, -3.29, 5.08]}
            rotation={[0.48, -0.63, -Math.PI]}
          ><Thread /></mesh>
          <mesh
            name="Torus_13_remesh_5"
            geometry={nodes.Torus_13_remesh_5.geometry}
            position={[-0.09, -3.31, 5.12]}
            rotation={[-2.66, -0.61, 0]}
          ><Thread /></mesh>
          <mesh
            name="Torus_12_remesh_5"
            geometry={nodes.Torus_12_remesh_5.geometry}
            position={[-0.22, -3.22, 4.95]}
            rotation={[-2.66, -0.61, 0]}
          ><Thread /></mesh>
          <mesh
            name="Torus_11_remesh_5"
            geometry={nodes.Torus_11_remesh_5.geometry}
            position={[-0.35, -3.13, 4.78]}
            rotation={[-2.66, -0.61, 0]}
          ><Thread /></mesh>
          <mesh
            name="Torus_10_remesh_5"
            geometry={nodes.Torus_10_remesh_5.geometry}
            position={[-0.48, -3.05, 4.61]}
            rotation={[-2.66, -0.52, 0]}
          ><Thread /></mesh>
          <mesh
            name="Torus_9_remesh_5"
            geometry={nodes.Torus_9_remesh_5.geometry}
            position={[-0.54, -2.95, 4.42]}
            rotation={[-2.66, -0.03, 0]}
          ><Thread /></mesh>
          <mesh
            name="Torus_8_remesh_5"
            geometry={nodes.Torus_8_remesh_5.geometry}
            position={[-0.54, -2.84, 4.22]}
            rotation={[-2.66, 0, 0]}
          ><Thread /></mesh>
          <mesh
            name="Torus_7_remesh_5"
            geometry={nodes.Torus_7_remesh_5.geometry}
            position={[-0.54, -2.74, 4.02]}
            rotation={[-2.66, 0, 0]}
          ><Thread /></mesh>
          <mesh
            name="Torus_6_remesh_5"
            geometry={nodes.Torus_6_remesh_5.geometry}
            position={[-0.54, -2.63, 3.82]}
            rotation={[-2.66, 0, 0]}
          ><Thread /></mesh>
          <mesh
            name="Torus_5_remesh_5"
            geometry={nodes.Torus_5_remesh_5.geometry}
            position={[-0.54, -2.53, 3.62]}
            rotation={[-2.66, 0, 0]}
          ><Thread /></mesh>
          <mesh
            name="Torus_4_remesh_5"
            geometry={nodes.Torus_4_remesh_5.geometry}
            position={[-0.46, -2.46, 3.5]}
            rotation={[-2.66, Math.PI / 2, 0]}
          ><Thread /></mesh>
          <mesh
            name="Torus_3_remesh_5"
            geometry={nodes.Torus_3_remesh_5.geometry}
            position={[-0.23, -2.46, 3.5]}
            rotation={[-2.66, Math.PI / 2, 0]}
          ><Thread /></mesh>
          <mesh
            name="Torus_2_remesh_5"
            geometry={nodes.Torus_2_remesh_5.geometry}
            position={[0.01, -2.46, 3.5]}
            rotation={[-2.66, Math.PI / 2, 0]}
          ><Thread /></mesh>
          <mesh
            name="Torus_1_remesh_5"
            geometry={nodes.Torus_1_remesh_5.geometry}
            position={[0.23, -2.46, 3.5]}
            rotation={[-2.66, Math.PI / 2, 0]}
          ><Thread /></mesh>
          <mesh
            name="Torus_0_remesh_5"
            geometry={nodes.Torus_0_remesh_5.geometry}
            position={[0.45, -2.46, 3.5]}
            rotation={[-2.66, Math.PI / 2, 0]}
          ><Thread /></mesh>
          <mesh
            name="Torus_23_remesh_2"
            geometry={nodes.Torus_23_remesh_2.geometry}
            position={[0.56, -0.36, -0.54]}
            rotation={[0.48, 0, Math.PI]}
          ><Thread /></mesh>
          <mesh
            name="Torus_22_remesh_6"
            geometry={nodes.Torus_22_remesh_6.geometry}
            position={[0.56, -0.46, -0.33]}
            rotation={[0.48, 0, Math.PI]}
          ><Thread /></mesh>
          <mesh
            name="Torus_21_remesh_6"
            geometry={nodes.Torus_21_remesh_6.geometry}
            position={[0.56, -0.57, -0.12]}
            rotation={[0.48, 0, Math.PI]}
          ><Thread /></mesh>
          <mesh
            name="Torus_20_remesh_6"
            geometry={nodes.Torus_20_remesh_6.geometry}
            position={[0.56, -0.67, 0.08]}
            rotation={[0.48, 0, Math.PI]}
          ><Thread /></mesh>
          <mesh
            name="Torus_19_remesh_6"
            geometry={nodes.Torus_19_remesh_6.geometry}
            position={[0.56, -0.77, 0.27]}
            rotation={[0.48, 0, Math.PI]}
          ><Thread /></mesh>
          <mesh
            name="Torus_18_remesh_6"
            geometry={nodes.Torus_18_remesh_6.geometry}
            position={[0.56, -0.88, 0.47]}
            rotation={[0.48, 0, Math.PI]}
          ><Thread /></mesh>
          <mesh
            name="Torus_17_remesh_6"
            geometry={nodes.Torus_17_remesh_6.geometry}
            position={[0.56, -0.99, 0.69]}
            rotation={[0.48, 0, Math.PI]}
          ><Thread /></mesh>
          <mesh
            name="Torus_16_remesh_6"
            geometry={nodes.Torus_16_remesh_6.geometry}
            position={[0.45, -1.05, 0.79]}
            rotation={[-2.66, -Math.PI / 2, 0]}
          ><Thread /></mesh>
          <mesh
            name="Torus_15_remesh_6"
            geometry={nodes.Torus_15_remesh_6.geometry}
            position={[0.22, -1.05, 0.79]}
            rotation={[-2.66, -Math.PI / 2, 0]}
          ><Thread /></mesh>
          <mesh
            name="Torus_14_remesh_6"
            geometry={nodes.Torus_14_remesh_6.geometry}
            position={[0.01, -1.05, 0.79]}
            rotation={[-2.66, -Math.PI / 2, 0]}
          ><Thread /></mesh>
          <mesh
            name="Torus_13_remesh_6"
            geometry={nodes.Torus_13_remesh_6.geometry}
            position={[-0.21, -1.05, 0.79]}
            rotation={[-2.66, -Math.PI / 2, 0]}
          ><Thread /></mesh>
          <mesh
            name="Torus_12_remesh_6"
            geometry={nodes.Torus_12_remesh_6.geometry}
            position={[-0.44, -1.05, 0.79]}
            rotation={[-2.66, -Math.PI / 2, 0]}
          ><Thread /></mesh>
          <mesh
            name="Torus_11_remesh_6"
            geometry={nodes.Torus_11_remesh_6.geometry}
            position={[-0.56, -0.99, 0.69]}
            rotation={[-2.66, 0, 0]}
          ><Thread /></mesh>
          <mesh
            name="Torus_10_remesh_6"
            geometry={nodes.Torus_10_remesh_6.geometry}
            position={[-0.56, -0.88, 0.48]}
            rotation={[-2.66, 0, 0]}
          ><Thread /></mesh>
          <mesh
            name="Torus_9_remesh_6"
            geometry={nodes.Torus_9_remesh_6.geometry}
            position={[-0.56, -0.78, 0.28]}
            rotation={[-2.66, 0, 0]}
          ><Thread /></mesh>
          <mesh
            name="Torus_8_remesh_6"
            geometry={nodes.Torus_8_remesh_6.geometry}
            position={[-0.56, -0.67, 0.07]}
            rotation={[-2.66, 0, 0]}
          ><Thread /></mesh>
          <mesh
            name="Torus_7_remesh_6"
            geometry={nodes.Torus_7_remesh_6.geometry}
            position={[-0.56, -0.57, -0.12]}
            rotation={[-2.66, 0, 0]}
          ><Thread /></mesh>
          <mesh
            name="Torus_6_remesh_6"
            geometry={nodes.Torus_6_remesh_6.geometry}
            position={[-0.56, -0.47, -0.31]}
            rotation={[-2.66, 0, 0]}
          ><Thread /></mesh>
          <mesh
            name="Torus_5_remesh_6"
            geometry={nodes.Torus_5_remesh_6.geometry}
            position={[-0.56, -0.36, -0.52]}
            rotation={[-2.66, 0, 0]}
          ><Thread /></mesh>
          <mesh
            name="Torus_4_remesh_6"
            geometry={nodes.Torus_4_remesh_6.geometry}
            position={[-0.53, -0.28, -0.69]}
            rotation={[-2.66, Math.PI / 2, 0]}
          ><Thread /></mesh>
          <mesh
            name="Torus_3_remesh_6"
            geometry={nodes.Torus_3_remesh_6.geometry}
            position={[-0.3, -0.28, -0.69]}
            rotation={[-2.66, Math.PI / 2, 0]}
          ><Thread /></mesh>
          <mesh
            name="Torus_2_remesh_6"
            geometry={nodes.Torus_2_remesh_6.geometry}
            position={[-0.05, -0.28, -0.69]}
            rotation={[-2.66, Math.PI / 2, 0]}
          ><Thread /></mesh>
          <mesh
            name="Torus_1_remesh_6"
            geometry={nodes.Torus_1_remesh_6.geometry}
            position={[0.18, -0.28, -0.69]}
            rotation={[-2.66, Math.PI / 2, 0]}
          ><Thread /></mesh>
          <mesh
            name="Torus_0_remesh_6"
            geometry={nodes.Torus_0_remesh_6.geometry}
            position={[0.39, -0.28, -0.69]}
            rotation={[-2.66, Math.PI / 2, 0]}
          ><Thread /></mesh>
          <mesh
            name="Torus_23_remesh_3"
            geometry={nodes.Torus_23_remesh_3.geometry}
            position={[0.54, -0.33, -0.59]}
            rotation={[0.48, 0, Math.PI]}
          ><Thread /></mesh>
          <mesh
            name="Torus_22_remesh_7"
            geometry={nodes.Torus_22_remesh_7.geometry}
            position={[0.54, -0.43, -0.38]}
            rotation={[0.48, 0, Math.PI]}
          ><Thread /></mesh>
          <mesh
            name="Torus_21_remesh_7"
            geometry={nodes.Torus_21_remesh_7.geometry}
            position={[0.54, -0.54, -0.18]}
            rotation={[0.48, 0, Math.PI]}
          ><Thread /></mesh>
          <mesh
            name="Torus_20_remesh_7"
            geometry={nodes.Torus_20_remesh_7.geometry}
            position={[0.54, -0.65, 0.02]}
            rotation={[0.48, 0, Math.PI]}
          ><Thread /></mesh>
          <mesh
            name="Torus_19_remesh_7"
            geometry={nodes.Torus_19_remesh_7.geometry}
            position={[0.54, -0.75, 0.22]}
            rotation={[0.48, 0, Math.PI]}
          ><Thread /></mesh>
          <mesh
            name="Torus_18_remesh_7"
            geometry={nodes.Torus_18_remesh_7.geometry}
            position={[0.54, -0.85, 0.42]}
            rotation={[0.48, 0, Math.PI]}
          ><Thread /></mesh>
          <mesh
            name="Torus_17_remesh_7"
            geometry={nodes.Torus_17_remesh_7.geometry}
            position={[0.54, -0.96, 0.63]}
            rotation={[0.48, 0, Math.PI]}
          ><Thread /></mesh>
          <mesh
            name="Torus_16_remesh_7"
            geometry={nodes.Torus_16_remesh_7.geometry}
            position={[0.47, -1.03, 0.77]}
            rotation={[-2.66, -Math.PI / 2, 0]}
          ><Thread /></mesh>
          <mesh
            name="Torus_15_remesh_7"
            geometry={nodes.Torus_15_remesh_7.geometry}
            position={[0.25, -1.03, 0.77]}
            rotation={[-2.66, -Math.PI / 2, 0]}
          ><Thread /></mesh>
          <mesh
            name="Torus_14_remesh_7"
            geometry={nodes.Torus_14_remesh_7.geometry}
            position={[0.03, -1.03, 0.77]}
            rotation={[-2.66, -Math.PI / 2, 0]}
          ><Thread /></mesh>
          <mesh
            name="Torus_13_remesh_7"
            geometry={nodes.Torus_13_remesh_7.geometry}
            position={[-0.19, -1.03, 0.77]}
            rotation={[-2.66, -Math.PI / 2, 0]}
          ><Thread /></mesh>
          <mesh
            name="Torus_12_remesh_7"
            geometry={nodes.Torus_12_remesh_7.geometry}
            position={[-0.42, -1.03, 0.77]}
            rotation={[-2.66, -Math.PI / 2, 0]}
          ><Thread /></mesh>
          <mesh
            name="Torus_11_remesh_7"
            geometry={nodes.Torus_11_remesh_7.geometry}
            position={[-0.54, -0.98, 0.67]}
            rotation={[-2.66, 0, 0]}
          ><Thread /></mesh>
          <mesh
            name="Torus_10_remesh_7"
            geometry={nodes.Torus_10_remesh_7.geometry}
            position={[-0.54, -0.88, 0.47]}
            rotation={[-2.66, 0, 0]}
          ><Thread /></mesh>
          <mesh
            name="Torus_9_remesh_7"
            geometry={nodes.Torus_9_remesh_7.geometry}
            position={[-0.54, -0.77, 0.26]}
            rotation={[-2.66, 0, 0]}
          ><Thread /></mesh>
          <mesh
            name="Torus_8_remesh_7"
            geometry={nodes.Torus_8_remesh_7.geometry}
            position={[-0.54, -0.66, 0.06]}
            rotation={[-2.66, 0, 0]}
          ><Thread /></mesh>
          <mesh
            name="Torus_7_remesh_7"
            geometry={nodes.Torus_7_remesh_7.geometry}
            position={[-0.54, -0.56, -0.14]}
            rotation={[-2.66, 0, 0]}
          ><Thread /></mesh>
          <mesh
            name="Torus_6_remesh_7"
            geometry={nodes.Torus_6_remesh_7.geometry}
            position={[-0.54, -0.46, -0.34]}
            rotation={[-2.66, 0, 0]}
          ><Thread /></mesh>
          <mesh
            name="Torus_5_remesh_7"
            geometry={nodes.Torus_5_remesh_7.geometry}
            position={[-0.54, -0.35, -0.54]}
            rotation={[-2.66, 0, 0]}
          ><Thread /></mesh>
          <mesh
            name="Torus_4_remesh_7"
            geometry={nodes.Torus_4_remesh_7.geometry}
            position={[-0.46, -0.29, -0.67]}
            rotation={[-2.66, Math.PI / 2, 0]}
          ><Thread /></mesh>
          <mesh
            name="Torus_3_remesh_7"
            geometry={nodes.Torus_3_remesh_7.geometry}
            position={[-0.23, -0.29, -0.67]}
            rotation={[-2.66, Math.PI / 2, 0]}
          ><Thread /></mesh>
          <mesh
            name="Torus_2_remesh_7"
            geometry={nodes.Torus_2_remesh_7.geometry}
            position={[0.01, -0.29, -0.67]}
            rotation={[-2.66, Math.PI / 2, 0]}
          ><Thread /></mesh>
          <mesh
            name="Torus_1_remesh_7"
            geometry={nodes.Torus_1_remesh_7.geometry}
            position={[0.23, -0.29, -0.67]}
            rotation={[-2.66, Math.PI / 2, 0]}
          ><Thread /></mesh>
          <mesh
            name="Torus_0_remesh_7"
            geometry={nodes.Torus_0_remesh_7.geometry}
            position={[0.45, -0.29, -0.67]}
            rotation={[-2.66, Math.PI / 2, 0]}
          ><Thread /></mesh>
        </group>
        <group name="Rivets" position={[-0.99, 14.98, -1.52]}>
          <mesh
            name="Brass"
            castShadow
            receiveShadow
            geometry={nodes.Brass.geometry}
            position={[6.37, 20.9, 0.77]}
            rotation={[1.56, -0.02, -1.6]}
          ><Brass /></mesh>
          <mesh
            name="Brass_1"
            castShadow
            receiveShadow
            geometry={nodes.Brass_1.geometry}
            position={[-4.9, 20.69, 0.62]}
            rotation={[1.58, 0.04, 1.63]}
          ><Brass /></mesh>
          <mesh
            name="Brass_2"
            castShadow
            receiveShadow
            geometry={nodes.Brass_2.geometry}
            position={[-5.13, 21.74, 0.61]}
            rotation={[1.56, 0.27, 1.63]}
          ><Brass /></mesh>
          <mesh
            name="Brass_3"
            castShadow
            receiveShadow
            geometry={nodes.Brass_3.geometry}
            position={[6.71, -12.7, 0.91]}
            rotation={[2.78, 0.01, 0.01]}
          ><Brass /></mesh>
          <mesh
            name="Brass_4"
            castShadow
            receiveShadow
            geometry={nodes.Brass_4.geometry}
            position={[0.63, -12.66, 6.82]}
            rotation={[1.53, 1.2, 1.61]}
          ><Brass /></mesh>
          <mesh
            name="Brass_5"
            castShadow
            receiveShadow
            geometry={nodes.Brass_5.geometry}
            position={[-5.28, -12.63, 0.74]}
            rotation={[0.36, -0.01, 3.14]}
          ><Brass /></mesh>
          <mesh
            name="Brass_6"
            castShadow
            receiveShadow
            geometry={nodes.Brass_6.geometry}
            position={[6.59, 21.96, 0.76]}
            rotation={[1.56, -0.25, -1.6]}
          ><Brass /></mesh>
        </group>
        <group name="PatinaBrass" position={[2.66, 19.63, -0.73]}>
          <mesh
            name="Patina"
            castShadow
            receiveShadow
            geometry={nodes.Patina.geometry}
            position={[-3.91, -17.31, -6.05]}
            rotation={[-0.04, -1.41, 3.11]}
          ><BrassPatinated /></mesh>
          <mesh
            name="Patina_1"
            castShadow
            receiveShadow
            geometry={nodes.Patina_1.geometry}
            position={[-1.8, -17.33, -6.02]}
            rotation={[-3.11, -1.38, 0.03]}
          ><BrassPatinated /></mesh>
          <mesh
            name="Patina_2"
            castShadow
            receiveShadow
            geometry={nodes.Patina_2.geometry}
            position={[-2.94, -17.31, 0.03]}
            rotation={[0.38, 1.56, -0.38]}
          ><BrassPatinated /></mesh>
        </group>
        <group name="Leather" position={[-0.26, 25.31, -0.78]}>
          <mesh
            name="Leather_1"
            castShadow
            receiveShadow
            geometry={nodes.Leather_1.geometry}
            position={[5.68, 11.12, 0.03]}
            rotation={[1.56, -0.02, -1.6]}
          ><Leather/></mesh>
          <mesh
            name="Leather_2"
            castShadow
            receiveShadow
            geometry={nodes.Leather_2.geometry}
            position={[-5.67, 10.9, -0.12]}
            rotation={[1.58, 0.04, 1.63]}
          ><Leather/></mesh>
          <mesh
            name="Leather_3"
            castShadow
            receiveShadow
            geometry={nodes.Leather_3.geometry}
            position={[-0.01, -22.02, 0.09]}
            rotation={[-2.76, -1.56, 0.38]}
          ><Leather/></mesh>
        </group>
        <group
          name="Plastic"
          position={[-0.28, 0.61, -0.69]}
          rotation={[3.14, 0.01, -3.14]}
        >
          <mesh
            name="Plastic_1"
            castShadow
            receiveShadow
            geometry={nodes.Plastic_1.geometry}
            position={[0, 0.59, 0]}
            rotation={[-Math.PI, 1.57, 0]}
          ><Plastic /></mesh>
          <mesh
            name="Plastic_2"
            castShadow
            receiveShadow
            geometry={nodes.Plastic_2.geometry}
            position={[0, -0.27, 0]}
            rotation={[-Math.PI, 1.57, 0]}
          ><Plastic /></mesh>
          <mesh
            name="Plastic_3"
            castShadow
            receiveShadow
            geometry={nodes.Plastic_3.geometry}
            position={[0, -0.05, 0]}
            rotation={[-Math.PI, 1.57, 0]}
          ><Plastic /></mesh>
          <mesh
            name="Plastic_4"
            castShadow
            receiveShadow
            geometry={nodes.Plastic_4.geometry}
            position={[0, -0.05, 0]}
            rotation={[-Math.PI, 1.57, 0]}
          ><Plastic /></mesh>
          <mesh
            name="Plastic_5"
            castShadow
            receiveShadow
            geometry={nodes.Plastic_5.geometry}
            position={[0, -0.05, 0]}
            rotation={[-Math.PI, 1.57, 0]}
          ><Plastic /></mesh>
          <mesh
            name="Plastic_6"
            castShadow
            receiveShadow
            geometry={nodes.Plastic_6.geometry}
            position={[0, -0.05, 0]}
            rotation={[-Math.PI, 1.57, 0]}
          ><Plastic /></mesh>
        </group>
        <mesh
          name="Twill"
          castShadow
          receiveShadow
          geometry={nodes.Twill.geometry}

          position={[-0.17, 21.34, -0.72]}
          rotation={[3.08, -1.53, 3.08]}
        ><Twill /></mesh>
      </group>
    </group>
  );
}

useGLTF.preload("https://static.dbldose.com/vinarmourLG.glb");
